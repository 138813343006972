import "./main.css";
import Couple from "./couple.svg";
import HaccPdf from "./extra-information-hacc.pdf";
import VstayPdf from "./extra-information-vstay.pdf";

export default function Main({ tier }) {
	return (
		<div className="main">
			<h1 className="main__title">Welcome!</h1>
			<div className="main__image-container">
				<img src={Couple} width={250} height={313} />
			</div>
			<h2 className="main__subtitle">Please select an option from below</h2>
			<div className="main__links">
				<a href="https://bkb2h88e6ez.typeform.com/to/nuYXKcrb" target="_blank">RSVP</a>
				<a href={tier === "in-house" ? HaccPdf : VstayPdf} target="_blank">More Information</a>
				<a href="https://www.weddingshop.com/Buy/View/146226" target="_blank">Gift List</a>
			</div>
		</div>
	);
}