import "./entry.css";
import Warning from "./warning.png";

export default function Entry({ inputValue, onInputValueChanged, onSubmit, validationError }) {
	const formSubmit = (e) => {
		e.preventDefault();
		onSubmit();
	}

	return (
		<div className="entry">
			<h1 className="entry__title">Please enter your unique code</h1>
			<form onSubmit={formSubmit} className="entry__form">
				<div className="entry__form-inputs">
					<input value={inputValue} onChange={e => onInputValueChanged(e.target.value)} className="entry__input" />
					<button type="submit" className="entry__button">Enter</button>
				</div>
				{validationError &&
					(
						<div className="entry__validation">
							<img src={Warning} width={30} />
							<p>{validationError}</p>
						</div>
					)
				}
			</form>
		</div>
	);
}