import "./layout.css";
import HeaderImage from "./header.svg";
import Auchen from "./auchen.jpg";

const BACKGROUND_STYLE = {
	backgroundImage: `url(${Auchen})`
};

export default function Layout(props) {
	return (
		<div className="layout">
			<div className="layout__header">
				<img src={HeaderImage} width={300} />
			</div>
			<div className="layout__background" style={BACKGROUND_STYLE}>
				<div className="layout__children">
					{props.children}
				</div>
			</div>
			<div className="layout__footer">
				<p>Designed and Created by Ben & Gabbie</p>
			</div>
		</div>
	);
}