import { useState } from "react";
import './App.css';
import Entry from './components/Entry/Entry';
import Layout from './components/Layout/Layout';
import Main from "./components/Main/Main";

function App() {
	const [ codeInputValue, setCodeInputValue ] = useState("");
	const [ codeTierValue, setCodeTierValue ] = useState(null);
	const [ hasEnteredValidCode, setHasEnteredValidCode ] = useState(false);
	const [ hasSubmitted, setHasSubmitted ] = useState(false);
	const onSubmit = () => {
		const codes = {
			hacc: "in-house",
			vstay: "village"
		};
		const code = (codeInputValue || "").toLowerCase();
		const parsedCode = codes[code];
		setHasEnteredValidCode(!!parsedCode);
		setHasSubmitted(true);
		setCodeTierValue(parsedCode);
	};
	const validationError = hasSubmitted && !hasEnteredValidCode ? "Invalid code, please check your invitation" : null;
	const showMain = hasSubmitted && hasEnteredValidCode;
	return (
		<div className="App">
			<Layout>
				{!showMain && <Entry inputValue={codeInputValue} onInputValueChanged={setCodeInputValue} onSubmit={onSubmit} validationError={validationError} />}
				{showMain && <Main tier={codeTierValue} />}
			</Layout>
		</div>
	);
}

export default App;
